import React from 'react'
import Helmet from 'react-helmet'

const Basic = props => (
    <Helmet title={props.seo?.title}>
        <meta name="description" content={props.seo?.description}/>
        <meta name="keywords" content={props.seo?.title}/>
    </Helmet>
)

export default Basic
