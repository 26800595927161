import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import AdHeaderAMP from "../ads/adHeader.amp";
import {STRINGS} from "../../constants/strings";
import {URL} from '../../constants/urls'
import {FOLLOW_US} from '../../constants/followUs'
import {HeaderAMP} from '@cg-squad/ui-components'

const HeaderWrapperAMP = (props) => {

  const data = useStaticQuery(graphql`
  query HeaderCategoryAMPQuery {
    allCategories: allDatoCmsCategory(
      filter: {root: {eq: true}, website: {elemMatch: {name: {eq: "pt"}}}}
      sort: {order: ASC, fields: position}
    ) {
      nodes {
        slug
        title
        treeChildren {
          slug
          title
          position
        }
      }
    }
    }
    `)
    const logoSizes = {
      menuLogoWidth: 300,
      menuLogoHeight: 40,
      headerLogoWidth: 300,
      headerLogoHeight: 40
    }

    return <HeaderAMP path={props.path}
                      categoryPath={props.categoryPath}
                      subCategoryPath={props.subCategoryPath}
                      allCategories={data.allCategories}
                      AdHeaderAMP={AdHeaderAMP}
                      followUs={FOLLOW_US}
                      stringsConst={STRINGS} urlsConst={URL}
                      logoSizes={logoSizes}/>
}

export default HeaderWrapperAMP
