import React from 'react'
import {graphql} from 'gatsby';
import Layout from '../components/layout/index.amp';
import loadable from "@loadable/component";
import {Basic, Facebook, Twitter} from '../components/SEO'
import {metadata} from '../../config'

const ArticlePreview = loadable(() => import("./articlePreview.amp"));

const SubCategoryPage = ({data, pageContext, location}) => {
    const seo = {
        title: pageContext.title,
        description: `Discover all of our ${pageContext.title} articles, written by our knowledgeable team of writers.`
    }
    const url = `${metadata.url}/${pageContext.categorySlug}/${pageContext.slug}`;

    return (
        <Layout path={location.pathname}>
            <Basic seo={seo}/>
            <Twitter seo={seo} url={url}/>
            <Facebook seo={seo} url={url}/>
            <div className="wrapper">
                <div className={"w-full"}>
                    <div className="text-denim uppercase font-bold mb-4 pb-2 border-b-default border-gray-400 text-lg">{pageContext.title}</div>
                    {(data.allDatoCmsArticle.nodes || []).map((item) => {
                        return (
                            <ArticlePreview data={item}
                                            className={"border-b-default border-gray-400 mb-4 pb-2"}
                                            imageClass="mb-0 w-1/3 lg:mr-4 float-right ml-2 lg:float-left mt-8 md:mt-0"
                                            headingClass="font-serif leading-snug mb-1 md:mb-4 text-lg line-clamp l-c-5"
                                            textClass={"line-clamp l-c-3 mb-2"}
                                            imageFromUrl={true}
                                            imageMeta={{w: 370}}
                                            noFlag={true}/>
                        )
                    })}
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
    query ArticlesSubCategoryWiseAMP($slug: String) {
        allDatoCmsArticle(filter: {category: {elemMatch: {slug: {eq: $slug}}}, website: {elemMatch: {name: {eq: "pt"}}}}, sort: {order: DESC, fields: publishedAt}) {
            nodes {
                heading
                excerpt
                excerptNode {
                  childMarkdownRemark {
                    html
                  }
                }
                slug
                author {
                    name
                    slug
                }
                category {
                    title
                    slug
                    treeParent {
                        slug
                    }
                    position
                    website {
                        name
                    }
                }
                cover {
                    url
                }
                publishedAt
            }
            totalCount
        }
    }
`
export default SubCategoryPage
